.bodyHeader[data-v-480473ea] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid grey;
  height: 60px;
  padding-left: 20px;
  margin-bottom: 10px;
}
.bodyHeader .left[data-v-480473ea] {
    width: 50%;
    font-size: xx-large;
    font-weight: 900;
}
.bodyHeader .right[data-v-480473ea] {
    width: 38%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
